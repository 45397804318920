@import "./../../styles/variables";

.menuButton {
    position: fixed;
    top: 25px;
    right: 35px;
    z-index: 20;
    border: 0;
    display: flex;
    align-items: center;
    background: none;

    @media (max-width: $breakpoint-xs) {
        top: 10px;
        right: 10px;
    }

    p {
        position: relative;
        margin: 0 10px 0 0;
        font-family: $fontHeading;
        font-size: 16px;
        font-weight: 500;
        transition: color .3s ease;

        @media (max-width: $breakpoint-xs) {
            font-size: 14px;
        }

        &:after {
            content: '';
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            height: 2px;
            background: $white;
            transition: background .3s ease;
        }
    }

    div {
        position: relative;
        width: 44px;
        height: 44px;
        border-radius: 50%;
        background: $white;
        transition: transform .3s ease, background .3s ease;

        @media (max-width: $breakpoint-xs) {
            width: 35px;
            height: 35px;
        }
    }

    span {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: inline-block;
        width: 50%;
        height: 2px;
        background: $black;
        transition: background .3s ease;

        &:before,
        &:after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: $black;
            transform: translateY(-7px);
            transition: transform .3s ease, background .3s ease;

            @media (max-width: $breakpoint-xs) {
                top: 2px;
            }
        }

        &:after {
            transform: translateY(7px);

            @media (max-width: $breakpoint-xs) {
                transform: translateY(3px);
            }
        }
    }

    &.active {
        span {
            &:before {
                transform: translateY(-4px) translateX(7px) rotate(45deg) scaleX(0.6);

                @media (max-width: $breakpoint-xs) {
                    transform: translateY(-5px) translateX(5px) rotate(45deg) scaleX(0.5);
                }
            }

            &:after {
                transform: translateY(4px) translateX(7px) rotate(-45deg) scaleX(0.6);

                @media (max-width: $breakpoint-xs) {
                    transform: translateY(1px) translateX(5px) rotate(-45deg) scaleX(0.5);
                }
            }
        }
    }

    &.darkButton {
        color: $black;

        p {
            &:after {
                background: $black;
            }
        }

        div {
            background: $black;

            span {
                background: $white;

                &:before,
                &:after {
                    background: $white;
                }
            }
        }
    }
}

.nav {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 15;
    overflow: auto;
    display: grid;
    place-items: center;
    width: 300px;
    padding: 95px 40px;
    background: $black;

    @media (max-width: $breakpoint-xs) {
        width: 200px;
        padding: 95px 20px;
    }
}

.navContainer {
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    max-width: 1200px;
    height: 100%;

    @media (max-width: $breakpoint-xs) {
        overflow: auto;
        align-content: normal;
    }
}

.bar {
    margin: auto 0 0 auto;

    h6 {
        position: relative;
        display: inline-block;
        font-family: $fontBody;
        font-size: 16px;
        color: $gray;
        text-transform: uppercase;

        @media (max-width: $breakpoint-xs) {
            font-size: 12px;
        }

        &:after {
            content: '';
            position: absolute;
            left: auto;
            right: 1px;
            bottom: 0;
            width: 70%;
            height: 1px;
            background: $gray;
        }
    }
}

.links {
    margin-left: auto;
    margin-bottom: auto;
    font-family: $fontHeading;
    font-size: 25px;
    text-align: right;

    @media (max-width: $breakpoint-xs) {
        font-size: 20px;
    }

    ul {
        margin: 50px 0;

        @media (max-width: $breakpoint-xs) {
            margin: 30px 0;
        }
    }

    li {
        position: relative;

        + li {
            margin-top: 35px;

            @media (max-width: $breakpoint-xs) {
                margin-top: 25px;
            }
        }

        &.linkActive {
            a {
                color: $white;
            }
        }
    }

    a {
        padding-right: 10px;
        text-decoration: none;
        color: $orange;

        &:after {
            content: '';
            position: absolute;
            top: 50%;
            right: 0;
            transform: translateY(-50%);
            width: 2px;
            height: 80%;
            background: currentColor;
        }

        &:hover {
            color: $gray;
        }
    }
}

.contacts {
    margin-left: auto;

    @media (max-width: $breakpoint-xs) {
        display: none;
        flex: 0 1 100%;
    }

    h2 {
        color: $gray;
    }
}

.contactItems {
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    a {
        + a {
            margin-top: 30px;
        }
    }
}
