@import "./../../styles/variables";

.button {
    display: inline-block;
    border: 2px solid $cyan;
    border-radius: 5px;
    background: none;
    padding: 6px 40px;
    font-size: $fontBase;
    color: $cyan;
    text-decoration: none;
    cursor: pointer;
    transition: background .3s ease, color .3s ease;
}

.white {
    border-color: $white;
    color: $white;

    &:hover {
        background: $white;
        color: $orange;
    }
}

.cyan {
    border-color: $orange;
    background: $orange;
    color: $white;

    &:hover {
        background: $black;
        color: $orange;
    }
}
