@import "./../../styles/variables";

.root {
    > div {
        display: flex;
        align-items: center;

        @media (max-width: $breakpoint-xs) {
            h1 {
                margin-bottom: 25px;
                font-size: 35px;
            }
        }

        @media (max-width: $breakpoint-xxs) {
            h1 {
                font-size: 30px;
            }
        }
    }
}

.expand {
    flex: 0 1 100%;
}
