@import "./../../styles/variables";

.root {
    > div {
        z-index: 5;
        overflow-x: hidden;
        overflow-y: auto;
    }
}

.info {
    max-width: 530px;
    padding: 50px 50px 40px;

    @media (max-width: $breakpoint-sm) {
        max-width: none;
        padding: 50px 15px 30px;
    }
}

.infoInner {
    @media (max-width: $breakpoint-sm) {
        border-radius: 5px;
        padding: 5px;
        background: rgba($black, 0.5);
    }
}

.overlayBg {
    position: fixed;
    top: 0;
    left: -20px;
    z-index: -5;
    min-width: 1676px;
    width: 200vh;
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 100% 0;
    pointer-events: none;

    @media (max-width: $breakpoint-sm) {
        min-width: 850px;
        width: 150vh;
    }
}
