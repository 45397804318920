@import "./../../styles/variables";

.root {
    display: flex;
    align-items: center;
    border: 1px solid $orange;
    border-radius: 5px;
    padding: 14px 22px;
    background: $orange;
    box-shadow: 2px 2px 10px rgba($black, 0.25);
    cursor: pointer;
    transition: background .3s ease;

    @media (max-width: $breakpoint-xs) {
        padding: 10px 15px;
    }

    > svg {
        flex-shrink: 0;
        margin: 0 22px auto 0;
        
        path {
            fill: #000;
            transition: fill .3s ease;
        }

        @media (max-width: $breakpoint-xs) {
            width: 35px;
            margin-right: 18px;
        }
    }

    h4 {
        margin-right: 10px;
        margin-bottom: 0;
        font-size: 21px;
        color: #000;
        transition: color .3s ease;

        @media (max-width: $breakpoint-xs) {
            font-size: 20px;
        }

        @media (max-width: $breakpoint-xxs) {
            font-size: 18px;
        }
    }

    button {
        display: inline-block;
        margin-left: auto;
        border: 0;
        background: none;
        line-height: 0;
        transition: transform .3s ease;

        rect {
            transition: opacity .3s ease;
        }

        svg {
            @media (max-width: $breakpoint-xs) {
                width: 33px;
            }
        }
    }
}

.active {
    background: $black;

    > svg {
        path {
            fill: $white;
        }
    }

    h4 {
        color: $white;
    }

    button {
        transform: rotate(90deg);

        svg {
            rect {
                opacity: 0;

                + rect {
                    opacity: 1;
                }
            }
        }
    }
}

.accordionContent {
    border-radius: 5px;
    padding: 20px;
    background: $black;
    box-shadow: 2px 2px 10px rgba($black, 0.25);

    @media (max-width: $breakpoint-xs) {
        padding: 15px;
    }

    h4 {
        margin-bottom: 15px;
        font-size: 22px;

        @media (max-width: $breakpoint-xxs) {
            font-size: 20px;
        }
    }
}

.disabled {
    pointer-events: none;
}
