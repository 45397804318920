@import "./../../styles/variables";

.root {
    > div {
        z-index: 5;
        overflow-x: hidden;
        overflow-y: auto;
    }
}

.container {
    height: 100%;
}

.content {
    position: relative;
    flex-shrink: 0;
    max-width: 600px;
    height: 100%;

    @media (max-width: $breakpoint-lg) {
        max-width: 500px;
    }

    @media (max-width: $breakpoint-sm) {
        max-width: 400px;
    }

    @media (max-width: $breakpoint-xs) {
        max-width: none;
    }

    figure {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 5;
        min-width: 980px;
        width: 120vh;
        height: 100%;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: 100% 0;
        pointer-events: none;

        @media (max-width: $breakpoint-lg) {
            min-width: 840px;
            width: 110vh;
        }

        @media (max-width: $breakpoint-sm) {
            width: 83vh;
        }

        @media (max-width: $breakpoint-xs) {
            min-width: 333px;
            width: 50vh;
        }
    }
}

.image {
    position: fixed;
    top: 0;
    left: 600px;
    right: 0;
    bottom: 0;
    background-size: cover;
    background-position: 50% 50%;

    @media (max-width: $breakpoint-lg) {
        left: 500px;
    }

    @media (max-width: $breakpoint-sm) {
        left: 400px;
    }

    @media (max-width: $breakpoint-xs) {
        left: 0;
    }
}

.contentInner {
    position: relative;
    z-index: 10;
    padding: 50px;

    @media (max-width: $breakpoint-sm) {
        padding: 50px 15px;
    }
}

.contentBg {
    position: fixed;
    top: 0;
    left: 600px;
}
