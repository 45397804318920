@import "./../../styles/variables";

@keyframes rotate {
    0% { transform: rotate(0deg) }
    100% { transform: rotate(180deg) }
}

.root {
    height: 100%;
    overflow: auto;

    @media (max-width: $breakpoint-sm) {
        display: flex;
        flex-wrap: wrap;
    }
}

.map {
    position: fixed;
    top: 0;
    left: 0;
    overflow: hidden;
    display: grid;
    place-items: center;
    width: 50%;
    height: 100%;

    iframe {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 5;
    }

    @media (max-width: $breakpoint-sm) {
        position: relative;
        width: 100%;
        height: 500px;
        order: 1;
    }

    @media (max-width: $breakpoint-xxs) {
        height: 400px;
    }

    iframe {
        width: 100%;
        height: 100%;
    }
}

.content {
    width: 50%;
    height: 100%;
    padding: 80px 40px 0;
    margin-left: auto;

    @media (max-width: $breakpoint-sm) {
        padding-top: 50px;
    }

    @media (max-width: $breakpoint-sm) {
        width: 100%;
        height: auto;
        padding: 50px 15px 0;
    }
}

.contentInner {
    padding-bottom: 60px;

    h1 {
        color: $white;
    }

    @media (max-width: $breakpoint-sm) {
        padding-bottom: 60px;
    }

    @media (max-width: $breakpoint-xxs) {
        padding-bottom: 40px;
    }
}

.listContacts {
    display: flex;
    flex-wrap: wrap;
    max-width: 580px;
    margin: -30px -20px 40px;
    list-style: none;

    @media (max-width: $breakpoint-xs) {
        display: block;
        margin: -15px 0 40px;
    }

    li {
        padding: 30px 20px 0;

        @media (max-width: $breakpoint-xs) {
            padding: 15px 0 0;
        }
    }
}

.listImages {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: -20px -10px 0;
    list-style: none;

    @media (max-width: $breakpoint-xxs) {
        display: block;
    }

    li {
        flex: 0 1 50%;
        padding: 20px 10px 0;

        @media (max-width: $breakpoint-xxs) {
            width: 100%;
            text-align: center;
        }
    }
}

.image {
    text-align: center;

    img {
        border-radius: 5px;
    }
}

.mapLoader {
    width: 50px;
    height: 50px;
    animation: rotate infinite 1s;
}
