@import "./../../styles/variables";

.root {
    display: flex;
    align-items: center;
    height: 100%;
    border-radius: 5px;
    padding: 15px 78px 15px 15px;
    background: $orange;
    box-shadow: 2px 2px 15px rgba($cyan, 0.5);

    @media (max-width: $breakpoint-xxs) {
        padding-right: 40px;
    }

    svg {
        margin: 0 20px auto 0;

        path {
            fill: #000;
        }
    }

    h3 {
        flex: 1;
        font-size: 20px;
        color: #000;
        text-align: center;

        @media (max-width: $breakpoint-xxs) {
            font-size: 18px;
        }
    }
}
