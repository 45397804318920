@import "./../../styles/variables";

.wrapper {
    > div {
        overflow: auto;
    }
}

.aboutGrid {
    display: flex;

    > div {
        flex: 0 1 50%;
        max-width: 50%;
        padding: 80px 100px 40px 0;

        @media (max-width: $breakpoint-sm) {
            padding: 50px 15px 40px 0;
        }

        @media (max-width: $breakpoint-xs) {
            position: relative;
            z-index: 5;
            flex: 0 1 100%;
            max-width: 100%;
            padding: 50px 0 40px 0;
        }

        img {
            position: fixed;
            left: 0;
            bottom: 0;
            z-index: -5;
            width: 50%;
            opacity: .6;

            @media (max-width: $breakpoint-xs) {
                display: none;
            }
        }
    }

    figure {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: block;
        background-size: cover;
        background-position: 50% 50%;

        @media (max-width: $breakpoint-xs) {
            width: 100%;
            opacity: .2;
        }
    }
}

.content {
    position: relative;
    z-index: 5;
    max-width: 800px;
    padding: 50px;

    @media (max-width: $breakpoint-sm) {
        max-width: 600px;
    }

    @media (max-width: $breakpoint-xs) {
        padding: 50px 15px;
    }
}

.contentBackground {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    max-width: 1000px;
    height: 120vh;
    z-index: -5;
    pointer-events: none;

    @media (max-width: $breakpoint-sm) {
        left: -200px;
        width: auto;
    }

    @media (max-width: $breakpoint-xs) {
        left: 0;
        width: 140%;
    }
}
