@import "./../../styles/variables";

.root {
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid $orange;
    border-radius: 5px;
    padding: 18px 15px 20px;
    background: $orange;
    text-align: center;
    box-shadow: 0 4px 4px rgba(0, 0, 0, .25);
    cursor: pointer;
    transition: background .3s ease;

    ~ .root {
        margin-top: 25px;
    }

    svg {
        margin-bottom: 10px;
        fill: #000;

        path {
            fill: #000;
            transition: fill .3s ease;
        }
    }

    h4 {
        font-size: 21px;
        color: #000;
        transition: color .3s ease;

        @media (max-width: $breakpoint-xxs) {
            font-size: 18px;
        }
    }

    button {
        margin-top: auto;
    }
}

.content {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    width: 40%;
    background: rgba($black, 0.95);

    @media (max-width: $breakpoint-md) {
        width: 50%;
    }

    @media (max-width: $breakpoint-sm) {
        width: 70%;
    }

    @media (max-width: $breakpoint-xs) {
        width: 90%;
    }

    @media (max-width: $breakpoint-xxs) {
        width: 94%;
    }
}

.contentInner {
    overflow: auto;
    height: 100%;
    padding: 50px 30px;

    @media (max-width: $breakpoint-xxs) {
        padding-top: 40px;
    }

    h4 {
        padding-right: 60px;

        @media (max-width: $breakpoint-xs) {
            margin-bottom: 25px;
        }

        @media (max-width: $breakpoint-xxs) {
            margin-bottom: 20px;
            font-size: 25px;
        }
    }

    p {
        color: $white;

        @media (max-width: $breakpoint-xxs) {
            font-size: 16px;
        }
    }

    img {
        display: block;
        margin: 0 auto 16px;
    }

    ul {
        list-style: none;

        li {
            position: relative;
            padding-left: 20px;

            + li {
                margin-top: 15px;
            }

            &:before {
                content: '';
                position: absolute;
                top: 6px;
                left: 0;
                width: 8px;
                height: 8px;
                border-radius: 50%;
                background: $orange;
            }
        }
    }
}

.closeButton {
    position: absolute;
    top: 50%;
    left: -18px;
    transform: translateY(-50%);
    width: 40px;
    height: 40px;
    border: 1px solid $black;
    border-radius: 50%;
    background: $white;

    &:before,
    &:after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) rotate(45deg);
        width: 20px;
        height: 2px;
        background: $black;
    }

    &:after {
        transform: translate(-50%, -50%) rotate(-45deg);
    }
}

.active {
    background: $black;

    svg {
        path {
            fill: $white;
        }
    }

    h4 {
        color: $white;
    }
}

.disabled {
    pointer-events: none;
}
