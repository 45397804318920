@import "./../../styles/variables";

.root {
    > div {
        overflow: auto;
        padding: 50px 0;
    }
}

.posts {
    display: flex;
    flex-wrap: wrap;
    margin: -30px -10px 0;
}

.post {
    flex: 0 1 33.33%;
    padding: 30px 10px 0;

    @media (max-width: $breakpoint-sm) {
        flex: 0 1 50%;
    }

    @media (max-width: $breakpoint-xs) {
        flex: 0 1 100%;
    }
}

.circleBg {
    position: fixed;
    top: -50%;
    left: -50%;
    z-index: -5;
    display: block;
    width: 100vw;
    height: 200vh;
    border-radius: 50%;
    background: $orange;
}

.spinner {
    margin: 100px auto 0;
}
