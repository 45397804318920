@import "./../../styles/variables";

.contactItem {
    display: inline-flex;
    align-items: center;
    border: 0;
    background: none;
    font-size: 20px;
    color: $gray;
    text-decoration: none;
    cursor: pointer;
    appearance: none;

    @media (max-width: $breakpoint-xs) {
        font-size: 20px;
    }

    svg {
        flex-shrink: 0;
        fill: $gray;
        margin-right: 10px;

        path {
            fill: $gray;
            transition: fill .3s ease;
        }
    }

    &:hover {
        color: $white;

        svg {
            path {
                fill: $white
            }
        }
    }
}

.dark {
    color: $cyan;

    svg {
        path {
            fill: $cyan
        }
    }

    &:hover {
        color: rgba($cyan, 0.6);

        svg {
            path {
                fill: rgba($cyan, 0.6)
            }
        }
    }
}

.inactive {
    pointer-events: none;
}
