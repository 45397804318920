@import "./styles/variables";

.app {
    transition: transform .5s ease;
}

.appMoved {
    transform: translateX(-300px);

    @media (max-width: $breakpoint-xs) {
        transform: translateX(-200px);
    }
}
