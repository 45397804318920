@import "./../../styles/variables";

.lineBorder {
    position: relative;
    padding-left: 0.34em;
    color: $white;

    &:before {
        content: '';
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        height: 90%;
        border-left: 0.1em solid;
    }
}

.white {
    color: $white;
}

.smallTitle {
    margin-bottom: 40px;
    font-size: 30px;

    @media (max-width: $breakpoint-sm) {
        margin-bottom: 25px;
    }

    @media (max-width: $breakpoint-xs) {
        font-size: 30px;
    }
}
