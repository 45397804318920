.root {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
}

.left {
    transform: rotateY(180deg);
}
