@import "./../../styles/variables";

$br-large-screen: 1600px;
$br-medium-screen: 1399px;
$br-small-screen: 1279px;
$br-small-tablet-screen: 1023px;
$br-xs-screen: 767px;
$br-xxs-screen: 413px;

.wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    background: linear-gradient(51deg, rgba(34,34,34,1) 0%, rgba(77,55,29,1) 50%, rgba(212,120,12,1) 100%);
}

.container {
    display: flex;
    align-items: flex-end;
    width: calc(100% - 840px);
    height: 100%;

    @media (max-width: $br-large-screen) {
        width: calc(100% - 700px);
    }

    @media (max-width: $br-medium-screen) {
        width: calc(100% - 500px);
    }

    @media (max-width: $br-small-screen) {
        width: calc(100% - 365px);
    }

    @media (max-width: $br-small-tablet-screen) {
        width: calc(100% - 315px);
    }

    @media (max-width: $br-xs-screen) {
        width: 100%;
    }
}

.backgroundSvg {
    position: fixed;
    top: 0;
    left: 50%;
    right: -50%;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: .1;

    svg {
        width: 30vw;
        height: 30vw;
        fill: #222;

        @media (max-width: $br-xs-screen) {
            width: 60vw;
            height: 60vw;
        }

        path {
            fill: #222;
        }
    }

    &.backgroundSvgLeft {
        left: -50%;
        right: 50%;

        svg {
            fill: #fff;

            path {
                fill: #fff;
            }
        }
    }
}

.mainCanvas {
    position: fixed;
    inset: 0;
    background-color: transparent !important;
}

.canvasImages {
    position: relative;
    z-index: 5;
    display: flex;
    width: 100%;
    justify-content: space-around;

    @media (max-width: $br-xxs-screen) {
        flex-wrap: wrap;
    }

    canvas {
        overflow: hidden;
        width: 100%;
        border-radius: 50%;
        cursor: pointer;
    }
}

.sideImageContainer {
    position: fixed;
    top: 0;
    right: -10px;
    bottom: 0;
    height: 100%;

    @media (max-width: $br-large-screen) {
        right: -145px;
    }

    @media (max-width: $br-medium-screen) {
        right: -10px;
    }

    @media (max-width: $br-small-tablet-screen) {
        right: -168px;
    }
}

.sideImage {
    @media (min-width: 1401px) and (min-height: 1081px) {
        width: 100vh;
        height: 100vh;
    }

    @media (max-width: 1399px) {
        width: 50vh;
        height: 100vh;
    }
}

.canvasImageContainer {
    position: relative;

    @media (max-width: $br-xxs-screen) {
        flex: 0 1 50%;
    }

    &:hover {
        span {
            transform: translate(-50%, 0);
            visibility: visible;
            opacity: 1;
        }
    }

    span {
        position: absolute;
        left: 50%;
        bottom: 10px;
        transform: translate(-50%, 100%);
        visibility: hidden;
        width: 80%;
        border-radius: 5px;
        padding: 5px;
        font-size: 14px;
        background: rgba($gray, 0.5);
        text-align: center;
        opacity: 0;
        transition: transform .3s ease, visibility .3s ease, opacity .3s ease;

        @media (max-width: $breakpoint-sm) {
            display: none;
        }
    }
}
