.enter {
    position: relative;
    opacity: 0;
    transform: translateX(-100px);
}

.enterActive {
    opacity: 1;
    transform: translateX(0);
    transition: .5s ease;
}

.exitActive {
    display: none;
}
