@import "./../../styles/variables";

.root {
    display: flex;
    align-items: flex-start;
    padding: 0 100px 50px 50px;

    @media (max-width: $breakpoint-lg) {
        padding-right: 50px;
    }

    @media (max-width: $breakpoint-sm) {
        max-width: none;
        padding: 0 15px 50px;
    }
}

.boxes {
    width: 100%;
    max-width: 530px;
    flex-shrink: 0;
    margin-right: 100px;

    @media (max-width: $breakpoint-md) {
        max-width: 450px;
        margin-right: 50px;
    }

    @media (max-width: $breakpoint-sm) {
        max-width: none;
        margin-right: 0;
    }

    > div {
        ~ div {
            margin-top: 25px;
        }
    }
}

.infoBox {
    flex: 0 1 720px;
    margin-left: auto;

    @media (max-width: $breakpoint-sm) {
        flex: 0 1 100%;
    }
}
