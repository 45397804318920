@import "./../../styles/variables";

.root {
    border-radius: 5px;
    padding: 5px 5px 20px;
    background: $black;
    box-shadow: 0 0 0 1px $orange;
    transition: transform .3s ease;

    &:hover {
        transform: translateY(-10px);
    }
}

.thumbnail {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 300px;
    border-radius: 5px;
    margin-bottom: 10px;
    background-size: cover;
    background-position: 50% 50%;
    background-color: $white;

    @media (max-width: $breakpoint-md) {
        height: 200px;
    }

    @media (max-width: $breakpoint-xs) {
        height: 150px;
    }

    svg {
        width: auto;
        height: 120px;

        @media (max-width: $breakpoint-md) {
            height: 80px;
        }

        @media (max-width: $breakpoint-xs) {
            height: 60px;
        }

        * {
            fill: $black;
        }
    }

    a {
        position: absolute;
        inset: 0;
    }
}

.meta {
    display: inline-block;
    margin-bottom: 5px;
    font-size: 15px;
}

h4 {
    @media (max-width: $breakpoint-xs) {
        font-size: 20px;
    }

    a {
        text-decoration: none;
    }
}
