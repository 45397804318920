.enter {
    opacity: 0;
}

.enterActive {
    opacity: 1;
    transition: opacity ease .6s;
}

.exit {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    opacity: 1;
}

.exitActive {
    position: fixed !important;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    opacity: 0;
    transition: opacity ease .6s;
}
