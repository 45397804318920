/* Font */
$fontHeading: 'Rubik', sans-serif;
$fontBody: 'Open Sans', sans-serif;

$white: #fff;
$cyan: #2C4A51;
$black: #222;
$blue: #253141;
$gray: #9C9C9C;
$orange: #C88116;

$fontBase: 16px;

/* Grid */
$containerWidth: 1280px;

/* Breakpoints */
$breakpoint-xxs: 413px;
$breakpoint-xs: 767px;
$breakpoint-sm: 1023px;
$breakpoint-md: 1279px;
$breakpoint-lg: 1399px;
