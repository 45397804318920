@import "./../../styles/variables";

.wrapper {
    background: $orange;

    > div {
        overflow: auto;
    }
}

.container {
    position: relative;
    max-width: 720px;

    @media (max-width: $breakpoint-sm) {
        max-width: 600px;
    }

    &:after {
        content: '';
        position: fixed;
        width: 1050px;
        height: 200vh;
        top: 50%;
        left: 50%;
        transform:  translate(-50%, -50%) rotate(16deg);
        background: $black;

        @media (max-width: $breakpoint-md) {
            transform:  translate(-50%, -50%) rotate(11deg);
            width: 875px;
        }

        @media (max-width: $breakpoint-sm) {
            transform:  translate(-50%, -50%) rotate(6deg);
            width: 716px;
        }
    }
}

.containerInner {
    position: relative;
    z-index: 5;
    padding: 50px 0;
}

.blogPost {
    h3 {
        margin-bottom: 20px;

        @media (max-width: $breakpoint-xs) {
            font-size: 30px;
        }
    }

    > img {
        width: 100%;
        margin-bottom: 10px;
        border-radius: 5px;
    }
}

.meta {
    display: block;
    margin-bottom: 30px;
    text-decoration: underline;
}

.content {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        margin-bottom: 20px;
    }

    ul {
        list-style: none;

        li {
            position: relative;
            padding-left: 20px;

            + li {
                margin-top: 2px;
            }

            &:before {
                content: '';
                position: absolute;
                top: 6px;
                left: 0;
                width: 8px;
                height: 8px;
                border-radius: 50%;
                background: $white;
            }
        }
    }

    img {
        margin-bottom: 16px;
    }

    blockquote {
        border-left: 5px solid $orange;
        padding-left: 15px;
    }
}

.spinner {
    margin: 100px auto 0;
}

.errorContainer {
    margin-top: 50px;
    text-align: center;

    a {
        margin-top: 30px;
    }
}
