@import "./../../styles/variables";

.root {
    border: 2px solid $white;
    border-radius: 5px;
    padding: 20px;
    background: #ff4242;
    color: $white;
    text-align: center;
}
