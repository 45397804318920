.root {
    position: relative;
    height: 100vh;
    background-size: cover;
    background-position: 50% 50%;
}

.overlayDark {
    &:before {
        content: '';
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(16, 21, 21, .5);
    }
}

.overlayLight {
    &:before {
        content: '';
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(255, 255, 255, .8);
    }
}

.inner {
    position: relative;
    width: 100%;
    height: 100%;
}
