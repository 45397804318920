@import "./../../styles/variables";

.root {
    > div {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 15px;
    }
}

.container {
    width: 100%;
    max-width: 800px;

    text-align: center;

    h1 {
        margin-bottom: 10px;
        font-size: 120px;
        color: $orange;

        @media (max-width: $breakpoint-xs) {
            font-size: 60px;
        }
    }

    h4 {
        @media (max-width: $breakpoint-xs) {
            margin-bottom: 20px;
            font-size: 20px;
        }
    }
}
