@import "./../../styles/variables";

.root {
    @media (max-width: $breakpoint-xs) {
        background-position: 50% 50%;
    }

    &:before {
        display: none;
    }

    > div {
        z-index: 5;
        overflow-x: hidden;
        overflow-y: auto;
    }
}

.info {
    margin-bottom: 35px;

    @media (max-width: $breakpoint-sm) {
        margin-bottom: 40px;
    }
}

.consultationsList {
    list-style: none;

    li {
        + li {
            margin-top: 20px;
        }
    }
}

.content {
    max-width: 600px;
    min-height: 100%;
    margin-left: auto;
    padding: 50px;
    background: linear-gradient(210deg, rgba(34,34,34,1) 0%, rgba(23,23,23,0.7) 100%);

    @media (max-width: $breakpoint-sm) {
        padding: 50px 15px;
    }

    @media (max-width: $breakpoint-xs) {
        max-width: none;
    }
}
